import React, { useEffect, useState } from "react";
import AreaDePracticaRuta from "../components/AreaDePracticaRuta.tsx";
import imgLaboral1 from "./../images/laboral_1.jpg";
import imgLaboral2 from "./../images/laboral_2.jpg";

const Laboral: React.FC = () => {
  return (
    <AreaDePracticaRuta
      titulo="Laboral"
      primeraDescripcion={
        <div className="flex flex-col text-justify">
          <p>
            En B&A brindamos asesoramiento integral en temas laborales tanto a
            trabajadores como a empleadores.
          </p>
          <h3 className="mt-1-5 mb-0-25 underline">Trabajador</h3>
          <p className="mb-0-25">Podemos ayudarle en cuestiones tales como:</p>
          <ul className="ul-style">
            <li className="li-style">Despidos.</li>
            <li className="li-style">Trabajo en negro o mal registrado.</li>
            <li className="li-style">
              Confección de los telegramas laborales.
            </li>
            <li className="li-style">
              Audiencias en SECLO o Ministerio de Trabajo.
            </li>
            <li className="li-style">
              Accidentes de trabajo y enfermedades profesionales.
            </li>
            <li className="li-style">Diferencias salariales.</li>
            <li className="li-style">Sanciones y suspensiones.</li>
            <li className="li-style">Indemnizaciones y liquidaciones.</li>
          </ul>
        </div>
      }
      segundaDescripcion={
        <div className="text-justify">
          <p className="mb-1-5">
            Ante cualquier tipo de irregularidad o controversia en su relación
            laboral, antes de actuar, consúltenos sobre los pasos a seguir.
            Tenga en cuenta que si usted renuncia a su trabajo el único que gana
            es su empleador y usted puede perder el derecho a reclamar por una
            justa indemnización.
          </p>
          <p>
            Nuestro estudio le brindará asesoramiento integral desde la primera
            consulta hasta el cobro, sin solicitar adelantos de dinero. El
            asesoramiento incluye desde la confección de los telegramas
            laborales, pasando por la etapa conciliatoria en el SECLO (Servicio
            de Conciliación Laboral Obligatoria) o ante el Ministerio de
            Trabajo, hasta llegar al reclamo judicial, en caso de ser necesario.
          </p>
          <h3 className="mt-1-5 mb-0-25 underline">Empleador</h3>
          <p className="mb-0-25">
            Como empleador le ayudamos a administrar de manera eficiente las
            contingencias laborales.
          </p>
        </div>
      }
      srcImagen1={imgLaboral1}
      srcImagen2={imgLaboral2}
    />
  );
};

export default Laboral;
