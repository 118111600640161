import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./../images/logo-mobile.jpg";
import HamburgerMenu from "./HamburgerMenu.tsx";

const Topbar: React.FC<any> = ({}) => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="topbar">
      <Link to="/" className="cursor-pointer z-150" onClick={scrollToTop}>
        <div>
          <img src={logo} alt="logo" className="logo-img" />
        </div>
      </Link>
      <div className="flex sm:hidden">
        <HamburgerMenu />
      </div>
      <div className="hidden sm:flex justify-between">
        <Link to="/" className="topbar-link" onClick={scrollToTop}>
          Inicio
        </Link>
        <div
          className="flex flex-col relative"
          onMouseEnter={() => setShowSubMenu(true)}
          onMouseLeave={() => setShowSubMenu(false)}
        >
          <a
            className="topbar-link flex items-center"
            href="/#areas-de-practica"
          >
            Áreas de Práctica <span className="triangulo-abajo"></span>
          </a>
          {showSubMenu && (
            <ul className={`areas-menu-container`}>
              <li>
                <Link to="/familia">Familia</Link>
              </li>
              <li>
                <Link to="/sucesiones">Sucesiones</Link>
              </li>
              <li>
                <Link to="/contratos">Contratos</Link>
              </li>
              <li>
                <Link to="/defensa-al-consumidor">Defensa Al Consumidor</Link>
              </li>
              <li>
                <Link to="/laboral">Laboral</Link>
              </li>
              <li>
                <Link to="/seguros">Seguros</Link>
              </li>
              {/* <li>
                <Link to="/accidentes-de-transito">Accidentes de Tránsito</Link>
              </li>
              <li>
                <Link to="/consorcios">Consorcios</Link>
              </li> */}
            </ul>
          )}
        </div>
        <a className="topbar-link" href="/#porque-elegirnos-id">
          Por qué Elegirnos
        </a>
        <a className="topbar-link" href="/#nuestro-equipo">
          Nuestro Equipo
        </a>
        <a className="topbar-link" href="/#contacto">
          Contacto
        </a>
      </div>
    </div>
  );
};

export default Topbar;
