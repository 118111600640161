import React, { useEffect, useRef } from "react";
import svgSuccess from "./../svg/success.svg";
import svgError from "./../svg/error.svg";

interface ModalEmailProps {
  sentOk: boolean;
  onClose: any;
  nombre: string;
}

const ModalEmail: React.FC<ModalEmailProps> = ({ sentOk, onClose, nombre }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [onClose]);

  return (
    <div className="modal-overlay">
      <div className="modal" ref={modalRef}>
        <span className="close-btn" onClick={onClose}>
          &times;
        </span>
        <div className={`modal-content`}>
          {sentOk ? (
            <div className="flex flex-col text-center">
              <p className="mb-1 text-lg">Gracias {nombre}!</p>
              <p className="mb-0-5">Su mensaje fué enviado correctamente.</p>
              <p className="mb-2">
                Nos estaremos contactando a la brevedad por los medios que nos
                proporcionó.
              </p>
              <div className="w-full flex justify-center">
                <img className="svg-modal" src={svgSuccess} />
              </div>
            </div>
          ) : (
            <div className="flex flex-col text-center">
              <p className="mb-1 text-lg">Error.</p>
              <p className="mb-0-5">
                Disculpanos {nombre}, Hubo un problema con el envio de tu
                mensaje.
              </p>
              <p className="mb-2">
                Por favor contacte con nosotros al whatsapp o mail
                proporcionados.
              </p>
              <div className="w-full flex justify-center">
                <img className="svg-modal" src={svgError} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalEmail;
