import React, { useEffect, useState } from "react";
import imgPorqueElegirnos from "./../images/porque-elegirnos-1.jpg";
import imgMain from "./../images/imagen-de-fondo-12.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import backSVG from "./../svg/back.svg";

const PorqueElegirnos: React.FC = () => {
  useEffect(() => {
    //AOS.init();
    AOS.init({
      disable: "mobile",
    });
  }, []);
  return (
    <div>
      <div data-aos="zoom-out" data-aos-duration="500" data-aos-offset="0">
        <img
          src={imgMain}
          alt="boniscontro-abogados"
          className="main-image-en-rutas"
        />
      </div>

      <div className="w-full flex justify-end">
        <Link
          to="/"
          className="w-fit-content flex mt-0-5 mr-1 items-center cursor-pointer"
        >
          <img className="svg-back" src={backSVG} />
          Inicio
        </Link>
      </div>

      <div className="flex flex-col sm:flex-row px-1 sm:px-6 py-1">
        <div
          className="flex flex-col mr-1-5"
          data-aos="fade-right"
          data-aos-duration="2000"
          data-aos-offset="0"
        >
          <h2 className="mb-2">¿POR QUÉ ELEGIRNOS?</h2>
          <h4 className="mb-0-5">Asesoramiento Jurídico Personalizado.</h4>
          <p className="text-justify">
            Entendemos que la persona que se acerca a nuestro Estudio Jurídico
            lo hace porque necesita solucionar su problema de la manera más
            rápida, eficiente y económica posible. Por ello en B&A partimos del
            estudio integral de los detalles propios de cada caso para luego
            poder elaborar la mejor estrategia para lograr una pronta, justa y
            conveniente defensa de los derechos involucrados.
          </p>

          <h4 className="mt-1 mb-0-5">Contención durante todo el proceso.</h4>
          <p className="text-justify">
            Sabemos que en toda situación donde se discuten los derechos y
            obligaciones de las parte, surgen emociones encontradas que muchas
            veces terminan afectando el estado anímico y relaciones afectivas de
            las personas involucradas. Por eso buscamos llegar al final del
            proceso con la menor cantidad de sufrimiento posible, acompañando a
            nuestros clientes durante cada una de sus etapas que se deban
            recorrer.
          </p>

          <h4 className="mt-1 mb-0-5">
            Seriedad, responsabilidad y experiencia.
          </h4>
          <p className="text-justify">
            Nuestra formación especializada en cada una de las áreas del derecho
            en la que ofrecemos nuestro servicio se une a la experiencia reunida
            en nuestra práctica profesional de la que pueden dar fe nuestros
            clientes.
          </p>

          <h4 className="mt-1 mb-0-5">
            Informes periódicos sobre el estado de las causas.
          </h4>
          <p className="text-justify">
            Sabemos que muchos de nuestros clientes necesitan estar informados
            periódicamente del estado de sus causas. Para satisfacer esa natural
            necesidad, en B&A facilitamos una fluida comunicación con nuestros
            clientes en todas las etapas del proceso.
          </p>

          <h4 className="mt-1 mb-0-5">
            Enfoque interdisciplinario y métodos alternativos de resolución de
            conflictos.
          </h4>
          <p className="text-justify">
            Nuestro Estudio se caracteriza por cuidar a las personas teniendo
            sobre ellas una mirada amplia, cuidando tanto el aspecto emocional
            como patrimonial, procurando llegar al cierre del proceso con el
            menor costo económico y anímico posibles. Por eso le dedicamos a la
            etapa pre-judicial todo el tiempo y esfuerzos necesarios para llegar
            a un acuerdo justo y evitar la judicialización cuando esta no es
            necesaria.
          </p>

          <h4 className="mt-1 mb-0-5">
            Razonabilidad y flexibilidad en materia de honorarios.
          </h4>
          <p className="text-justify">
            En B&A nos caracterizamos por la razonabilidad de nuestros
            honorarios profesionales y por la flexibilidad en cuanto a las
            formas de pago de los mismos.
          </p>

          <h4 className="mt-1 mb-0-5">Proximidad.</h4>
          <p className="text-justify">
            Nuestro Estudio está cerca suyo. Estamos en Tortuguitas, muy cerca
            de Del Viso, Garín; Escobar, Pilar y el resto de zona norte.
          </p>
          <p className="mt-1 text-justify">
            Contamos con matrícula profesional para operar en CABA y en toda la
            Provincia de Buenos Aires.
          </p>
        </div>
        <img
          src={imgPorqueElegirnos}
          alt="porque-elegirnos"
          className="imagen-porque-elegirnos h-full-on-mobile mt-2 sm:mt-0"
        />
      </div>
    </div>
  );
};

export default PorqueElegirnos;
