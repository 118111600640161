import React, { useEffect, useMemo, useState } from "react";
import facebookSVG from "./../svg/facebook.svg";
import linkedinSVG from "./../svg/linkedin.svg";
import whatsappSVG from "./../svg/whatsapp-negro.svg";

const SocialMedias: React.FC = ({}) => {
  const [bottomDisplay, setBottomDisplay] = useState<string>("none");

  const handleScroll = () => {
    const scrollY = window.scrollY;

    if (scrollY > 200) {
      setBottomDisplay("flex");
    } else {
      setBottomDisplay("none");
    }
  };

  useEffect(() => {
    if (window.innerWidth > 1000) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  return (
    <div className={`hidden social-medias`} style={{ display: bottomDisplay }}>
      <a
        href="https://api.whatsapp.com/send/?phone=%2B5491157519971&text&app_absent=0"
        target="_blank"
        rel="noopener"
      >
        <img className="svg-en-social-media mb-0-75" src={whatsappSVG} />
      </a>
      <a
        href="https://www.facebook.com/Boniscontro-Abogados-101155588414003/?view_public_for=101155588414003&ref=page_internal"
        target="_blank"
        rel="noopener"
      >
        <img className="svg-en-social-media mb-0-75" src={facebookSVG} />
      </a>
      <a
        href="https://www.linkedin.com/in/ana-maria-boniscontro-a47b0b113/"
        target="_blank"
        rel="noopener"
      >
        <img className="svg-en-social-media mb-0-5" src={linkedinSVG} />
      </a>
    </div>
  );
};

export default SocialMedias;
