import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import edificioSVG from "./../svg/edificio.svg";

interface HamburgerMenuProps {}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({}) => {
  const [isChecked, setChecked] = useState(false);
  const [showAreas, setShowAreas] = useState(false);
  const [flechaRotada, setFlechaRotada] = useState(false);

  useEffect(() => {
    if (isChecked) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isChecked]);

  const handleClickTopbar = (scrollToTop: boolean) => {
    if (scrollToTop) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setChecked(false);
    setShowAreas(false);
    setFlechaRotada(false);
  };
  return (
    <div className="menu-container">
      <input
        type="checkbox"
        className="input-checkbox-hamburger-menu"
        id="menu-toggle"
        checked={isChecked}
        onChange={() => setChecked(!isChecked)}
      />
      <label htmlFor="menu-toggle" className="menu-icon">
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </label>
      <div className="menu">
        <ul>
          <li>
            <Link
              to="/"
              className="menu-options"
              onClick={() => handleClickTopbar(true)}
            >
              Inicio
            </Link>
          </li>
          <li>
            <div
              className="flex justify-center w-full items-center"
              onClick={() => {
                setShowAreas(!showAreas);
                setFlechaRotada(!flechaRotada);
              }}
            >
              <a className="menu-options w-fit-content-important mr-0-5">
                Áreas de Práctica
              </a>
              <div
                className={`flecha-areas ${flechaRotada ? "arriba" : ""}`}
              ></div>
            </div>
          </li>
          {showAreas && (
            <li>
              <ul className="menu-areas">
                <li
                  className="menu-areas-options"
                  onClick={() => handleClickTopbar(false)}
                >
                  <Link to="/familia">Familia</Link>
                </li>
                <li
                  className="menu-areas-options"
                  onClick={() => handleClickTopbar(false)}
                >
                  <Link to="/sucesiones">Sucesiones</Link>
                </li>
                <li
                  className="menu-areas-options"
                  onClick={() => handleClickTopbar(false)}
                >
                  <Link to="/contratos">Contratos</Link>
                </li>
                <li
                  className="menu-areas-options"
                  onClick={() => handleClickTopbar(false)}
                >
                  <Link to="/defensa-al-consumidor">Defensa Al Consumidor</Link>
                </li>
                <li
                  className="menu-areas-options"
                  onClick={() => handleClickTopbar(false)}
                >
                  <Link to="/laboral">Laboral</Link>
                </li>
                <li
                  className="menu-areas-options"
                  onClick={() => handleClickTopbar(false)}
                >
                  <Link to="/seguros">Seguros</Link>
                </li>
                {/* <li
                  className="menu-areas-options"
                  onClick={() => handleClickTopbar(false)}
                >
                  <Link to="/accidentes-de-transito">
                    Accidentes de Tránsito
                  </Link>
                </li> */}
                {/* <li
                  className="menu-areas-options"
                  onClick={() => handleClickTopbar(false)}
                >
                  <Link to="/consorcios">Consorcios</Link>
                </li> */}
              </ul>
            </li>
          )}
          <li>
            <Link
              className="menu-options"
              to="/porque-elegirnos"
              onClick={() => handleClickTopbar(false)}
            >
              Por qué Elegirnos
            </Link>
          </li>
          <li>
            <a
              className="menu-options"
              href="/#nuestro-equipo"
              onClick={() => handleClickTopbar(false)}
            >
              Nuestro Equipo
            </a>
          </li>
          <li>
            <a
              className="menu-options"
              href="/#contacto"
              onClick={() => handleClickTopbar(false)}
            >
              Contacto
            </a>
          </li>
        </ul>
        <div className="flex w-full justify-center mt-3">
          <img className="w-7 h-7" style={{ opacity: 0.9 }} src={edificioSVG} />
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenu;
