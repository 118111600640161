import React, { useEffect, useState } from "react";
import AreaDePracticaRuta from "../components/AreaDePracticaRuta.tsx";
import imgSucesiones1 from "./../images/sucesiones_3.jpg";
import imgSucesiones2 from "./../images/sucesiones_2.jpg";

const Sucesiones: React.FC = () => {
  return (
    <AreaDePracticaRuta
      titulo="Sucesiones"
      primeraDescripcion={
        <div className="flex flex-col text-justify">
          <p className="mb-0-25">
            B&A se especializa en todo tipo de procesos sucesorios y brinda
            asesoramiento en la planificación de herencias futuras.
          </p>
          <p className="mb-0-25">
            Trabajamos con Juzgados de CABA y de Provincia de Bs. AS.
          </p>
          <p className="mb-0-25">
            Podemos responder por tus consultas en temas como:
          </p>
          <ul className="ul-style">
            <li className="li-style">
              Testamentos ológrafos o por Escritura Pública.
            </li>
            <li className="li-style">
              Sucesiones Ab Intestato o Testamentarias.
            </li>
            <li className="li-style">Denuncia de herencias Vacantes.</li>
            <li className="li-style">
              Cesión de derechos hereditarios y Gananciales.
            </li>
            <li className="li-style">Donaciones.</li>
            <li className="li-style">Defensa de la porción legitima.</li>
            <li className="li-style">Petición de herencia.</li>
            <li className="li-style">Inventario. Avalúo. Partición.</li>
            <li className="li-style">Declaratoria de herederos.</li>
            <li className="li-style">
              Venta de inmuebles por tracto abreviado.
            </li>
            <li className="li-style">
              Trámites ante el registro de propiedad de bienes inmuebles y
              muebles.
            </li>
            <li className="li-style">Usucapión como estrategia sucesoria..</li>
          </ul>
        </div>
      }
      segundaDescripcion={
        <div className="text-justify">
          <p className="mb-0-5">
            Contamos con amplia experiencia en la materia que nos califica para
            brindarte asesoramiento en planificación sucesoria y en procesos
            hereditarios de baja, mediana o alta complejidad.
          </p>
          <p className="mb-0-5">
            Es importante que sepas que un solo heredero puede comenzar los
            trámites sucesorios, no siendo necesario que lo inicien todos los
            herederos.
          </p>
          <p className="mb-0-5">
            La documentación requerida para iniciar el trámite sucesorio es muy
            poca. Solo necesitamos la partida de defunción del causante y en su
            caso, las partidas de nacimiento o de matrimonio de quiénes serán
            declarados como Herederos. En caso de no contar con esa
            documentación te ayudamos a conseguirla.
          </p>
          <p className="mb-0-5">
            Cuando la situación lo requiere, ofrecemos la posibilidad de
            entablar negociaciones con otros herederos, facilitando la solución
            de conflictos.
          </p>
          <p className="mb-0-5">
            Contamos con Escribanos de confianza e Inmobiliaria de prestigio que
            podrán orientarlo e intervenir a los fines de evitar todo tipo de
            demora en la inscripción o venta de los bienes a heredar.
          </p>
          <p className="mb-0-5">
            Honorarios a convenir con adecuación a las posibilidades de los
            herederos.
          </p>
        </div>
      }
      srcImagen1={imgSucesiones1}
      srcImagen2={imgSucesiones2}
    />
  );
};

export default Sucesiones;
