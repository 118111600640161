import React from "react";
import facebookSVG from "./../svg/facebook.svg";
import linkedinSVG from "./../svg/linkedin.svg";
import whatsappSVG from "./../svg/whatsapp-negro.svg";

const Footer: React.FC = ({}) => {
  return (
    <div className="footer-container">
      <div className="flex items-center justify-center mb-1 sm:mb-0">
        <a
          href="https://api.whatsapp.com/send/?phone=%2B5491157519971&text&app_absent=0"
          target="_blank"
          rel="noopener"
        >
          <img
            className="svg-en-footer"
            style={{ marginTop: "0.2rem" }}
            src={whatsappSVG}
          />
        </a>
        <a
          href="https://www.facebook.com/Boniscontro-Abogados-101155588414003/?view_public_for=101155588414003&ref=page_internal"
          target="_blank"
          rel="noopener"
        >
          <img className="svg-en-footer" src={facebookSVG} />
        </a>
        <a
          href="https://www.linkedin.com/in/ana-maria-boniscontro-a47b0b113/"
          target="_blank"
          rel="noopener"
        >
          <img className="svg-en-footer" src={linkedinSVG} />
        </a>
      </div>
      <div className="hidden sm:flex">
        © 2024 B&A | Boniscontro & Abogados | Todos los derechos reservados
      </div>
      <div className="flex flex-col sm:hidden">
        <div>© 2024 B&A | Boniscontro & Abogados</div>
        <div>Todos los derechos reservados</div>
      </div>
    </div>
  );
};

export default Footer;
