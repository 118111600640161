import React, { useEffect, useState } from "react";
import upSVG from "./../svg/up.svg";

const ScrollUp: React.FC = ({}) => {
  const [bottomPosition, setBottomPosition] = useState<string>("2rem");
  const [bottomDisplay, setBottomDisplay] = useState<string>("none");

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const screenHeight = window.innerHeight;
    const documentHeight = document.body.scrollHeight;

    // Ajusta el valor según tus necesidades
    const threshold = 100;

    if (scrollY > documentHeight - screenHeight - threshold) {
      setBottomPosition("7rem");
    } else {
      setBottomPosition("2rem");
    }

    if (scrollY > 200) {
      setBottomDisplay("block");
    } else {
      setBottomDisplay("none");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div
      className="scroll-up"
      onClick={scrollUp}
      style={{ bottom: bottomPosition, display: bottomDisplay }}
    >
      <img className="svg-en-scroll-up" src={upSVG} />
    </div>
  );
};

export default ScrollUp;
