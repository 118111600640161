import React from "react";
import { Link } from "react-router-dom";
import edificioSVG from "./../svg/edificio.svg";

interface AreaDePracticaProps {
  titulo: string;
  descripcion: string;
  link: string;
}

const AreaDePractica: React.FC<AreaDePracticaProps> = ({
  titulo,
  descripcion,
  link,
}) => {
  return (
    <Link
      to={link}
      className="grid-item relative"
      data-aos="zoom-in-up"
      data-aos-duration="1000"
      data-aos-offset="0"
    >
      <div className="titulo-area-de-practica">{titulo}</div>
      <p className="text-base mb-2">{descripcion}</p>
      <img className="svg-en-card" src={edificioSVG} />
      <p className="ver-mas">
        Ver Más <span className="triangulo-derecha"></span>
      </p>
    </Link>
  );
};

export default AreaDePractica;
