import React, { useEffect, useState } from "react";
import AreaDePracticaRuta from "../components/AreaDePracticaRuta.tsx";
import imgSeguros from "./../images/seguros.jpg";

const Seguros: React.FC = () => {
  return (
    <AreaDePracticaRuta
      titulo="Seguros"
      primeraDescripcion={
        <div className="flex flex-col text-justify">
          <p>
            En B&A nos especializamos en la gestión de los reclamos de
            siniestros ante todas las compañías aseguradoras y en todas las
            instancias de los reclamos por accidente de tránsito.
          </p>
          <p className="mt-1 mb-0-25">
            Nuestro principal objetivo y compromiso es brindar un servicio que
            le permita a nuestros clientes resolver sus reclamos, de una manera
            rápida y eficiente, ante:
          </p>
          <ul className="ul-style">
            <li className="li-style">La aseguradora que usted contrató.</li>
            <li className="li-style">
              La aseguradora de los otros involucrados en el siniestro. (Reclamo
              de terceros).
            </li>
          </ul>
          <p className="mt-1 -mb-2 sm:mb-0">
            Recuerde que para demandar a su aseguradora el plazo es de un año.
            No se demore para no perder su derecho.
          </p>
        </div>
      }
      srcImagen1={imgSeguros}
    />
  );
};

export default Seguros;
