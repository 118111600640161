import React, { useEffect, useState } from "react";
import AreaDePracticaRuta from "../components/AreaDePracticaRuta.tsx";
import imgDefensa from "./../images/defensa.jpg";

const DefensaAlConsumidor: React.FC = () => {
  return (
    <AreaDePracticaRuta
      titulo="Defensa Al Consumidor"
      primeraDescripcion={
        <div className="flex flex-col text-justify">
          <p>
            En B&A buscamos proteger y respaldar a nuestros clientes en aquellas
            situaciones en las que se vean afectados los derechos reconocidos en
            la Ley de defensa del consumidor.
          </p>
          <p className="mt-1 mb-0-25">
            Nuestros servicios abarcan todos los aspectos relacionados con
            abusos cometidos por:
          </p>
          <ul className="ul-style">
            <li className="li-style">Entidades financieras.</li>
            <li className="li-style">Compañías de Seguros.</li>
            <li className="li-style">Medicina prepaga.</li>
            <li className="li-style">Planes de ahorro previo.</li>
            <li className="li-style">Empresas de Servicios Públicos.</li>
            <li className="li-style">Supermercados.</li>
            <li className="li-style">Comercios en general.</li>
            <li className="li-style">Alimentos en mal estado.</li>
            <li className="li-style">Líneas aéreas.</li>
          </ul>
          <p className="mt-1">
            Ofrecemos a nuestros clientes el asesoramiento y la representación
            legal para llevar adelante sus reclamos si sienten que han sido
            engañados o perjudicados por los vendedores y/o prestadores del
            servicio, atento a que los consumidores, se encuentran en una clara
            desventaja en los hechos y nuestro estudio asume el compromiso de
            equiparar dicha desigualdad.
          </p>
        </div>
      }
      srcImagen1={imgDefensa}
    />
  );
};

export default DefensaAlConsumidor;
