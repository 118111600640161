import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import imgMain from "./../images/imagen-de-fondo-12.jpg";
import backSVG from "./../svg/back.svg";
import { Link } from "react-router-dom";

interface AreaDePracticaProps {
  titulo: string;
  primeraDescripcion: React.ReactElement;
  segundaDescripcion?: React.ReactElement;
  srcImagen1: string;
  srcImagen2?: string;
}

const AreaDePractica: React.FC<AreaDePracticaProps> = ({
  titulo,
  primeraDescripcion,
  segundaDescripcion,
  srcImagen1,
  srcImagen2,
}) => {
  useEffect(() => {
    //AOS.init();
    AOS.init({
      disable: "mobile",
    });
  }, []);
  return (
    <div className="">
      <div data-aos="zoom-out" data-aos-duration="500" data-aos-offset="0">
        <img
          src={imgMain}
          alt="boniscontro-abogados"
          className="main-image-en-rutas"
        />
      </div>
      <div className="flex flex-col px-1 sm:px-6 py-1 -mt-1">
        <div className="w-full flex justify-end">
          <Link
            to="/"
            className="w-fit-content flex mb-0-25 sm:mb-1-5 items-center cursor-pointer"
          >
            <img className="svg-back" src={backSVG} />
            Inicio
          </Link>
        </div>
        <div className="flex justify-between mb-2 sm:mb-3">
          <div
            className="flex flex-col mr-2"
            data-aos="fade-right"
            data-aos-duration="2000"
            data-aos-offset="0"
          >
            <h2 className="mb-1">{titulo}</h2>
            <div>{primeraDescripcion}</div>
          </div>
          <img
            src={srcImagen1}
            alt={titulo}
            className="imagen-area-ruta hidden sm:flex"
            data-aos="fade-left"
            data-aos-duration="2000"
            data-aos-offset="0"
          />
        </div>
        {segundaDescripcion && (
          <div className="flex ">
            <img
              src={srcImagen2}
              alt={titulo}
              className="imagen-area-ruta hidden sm:flex"
              data-aos="fade-right"
              data-aos-duration="2000"
              data-aos-offset="0"
            />
            <div
              className="sm:ml-2 "
              data-aos="fade-left"
              data-aos-duration="2000"
              data-aos-offset="0"
            >
              {segundaDescripcion}
            </div>
          </div>
        )}
        <div className="sm:hidden mt-2">
          <img
            src={srcImagen1}
            alt={titulo}
            className="imagen-area-ruta w-full"
            data-aos="fade-right"
            data-aos-duration="2000"
            data-aos-offset="0"
          />
        </div>
      </div>
    </div>
  );
};

export default AreaDePractica;
