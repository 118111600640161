import React, { useEffect, useState } from "react";
import AreaDePracticaRuta from "../components/AreaDePracticaRuta.tsx";
import imgContratos from "./../images/contratos-1.png";

const Contratos: React.FC = () => {
  return (
    <AreaDePracticaRuta
      titulo="Contratos"
      primeraDescripcion={
        <div className="flex flex-col text-justify">
          <p>
            Nuestro estudio jurídico, le brinda el asesoramiento y la redacción
            o revisión de todo tipo de contratos que usted deba suscribir.
          </p>
          <p className="mt-1 mb-0-25">Contratos Comerciales:</p>
          <ul className="ul-style">
            <li className="li-style">Contratos de compra - venta.</li>
            <li className="li-style">Contratos de Alquiler o locación.</li>
            <li className="li-style">Contratos de comodato.</li>
            <li className="li-style">Contratos de servicio.</li>
            <li className="li-style">Contratos de trabajo.</li>
            <li className="li-style">Contratos de obra.</li>
          </ul>
          <p className="mt-1 mb-1">
            Evite utilizar un texto modelo sacado de internet, que probablemente
            no se aplique a su situación concreta y no haga más que perjudicar
            su derecho. Lo que usted firma en un contrato es ley para las
            partes. Con lo cual, usted puede perder derechos si el contrato no
            está correctamente redactado.
          </p>
          <p className="mb-1">
            A cambio de un honorario fijo, lógico y razonable usted podrá contar
            con la redacción de su Contrato o la Revisión del mismo.
          </p>
          <p className="-mb-2 sm:mb-1">
            Si lo desea podrá concurrir a nuestro estudio y si no podrá hacerlo
            desde cualquier punto del país sin necesidad de perder tiempo en
            traslados. Vía e-mail, en forma telefónica o por video conferencia
            usted podrá contarnos las particularidades del caso.
          </p>
        </div>
      }
      srcImagen1={imgContratos}
    />
  );
};

export default Contratos;
