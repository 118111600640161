import React, { useEffect, useState } from "react";
import AreaDePracticaRuta from "../components/AreaDePracticaRuta.tsx";
import imgFamilia1 from "./../images/familia_1.jpg";
import imgFamilia2 from "./../images/familia_2.jpg";

const Familia: React.FC = () => {
  return (
    <AreaDePracticaRuta
      titulo="Familia"
      primeraDescripcion={
        <div className="flex flex-col text-justify">
          <p className="mb-0-25">
            En B&A procuramos brindarte contención y asesoramiento de calidad en
            cuestiones familiares como son:
          </p>
          <ul className="ul-style">
            <li className="li-style">Divorcio.</li>
            <li className="li-style">Cuidado personal.</li>
            <li className="li-style">Régimen comunicación.</li>
            <li className="li-style">Alimentos.</li>
            <li className="li-style">
              Determinación de la capacidad jurídica.
            </li>
            <li className="li-style">Violencia familiar.</li>
            <li className="li-style">Reclamo o impugnación de filiación.</li>
            <li className="li-style">Guarda, tutela y curatela.</li>
          </ul>
          <p className="mt-1">
            Cuando el conflicto surge en el ámbito familiar, los riesgos de que
            la intervención de un profesional del derecho traiga aparejado una
            escalada del mismo, son graves y lamentablemente muy frecuentes. Por
            eso nuestro Estudio te garantiza elaborar estrategias de resolución
            de los conflictos poniendo foco en el cuidado de las personas
            involucradas.
          </p>
        </div>
      }
      segundaDescripcion={
        <div className="text-justify">
          <h3 className="mb-0-25 underline">Divorcio Express</h3>
          <p className="mb-1-5">
            La implementación del nuevo código civil y comercial vigente desde
            el 1 de Agosto de 2015, nos permite iniciar un juicio de divorcio de
            manera unilateral, con la sola conformidad de la parte que solicita,
            ya sea en Capital Federal o en la Provincia de Buenos Aires. Hoy en
            día, el divorcio se otorga con o sin consentimiento de la otra
            parte.
          </p>
          <p className="mb-0-25">
            Podremos tramitar su divorcio, solo con la siguiente documentación:
          </p>
          <ul className="ul-style">
            <li className="li-style">
              Acta o libreta de matrimonio (original o copia legalizada).
            </li>
            <li className="li-style">
              En caso de tener hijos en común, las partidas de nacimiento de los
              hijos (originales o copias legalizadas).
            </li>
            <li className="li-style">
              Fotocopia de DNI del o los solicitantes.
            </li>
          </ul>
        </div>
      }
      srcImagen1={imgFamilia1}
      srcImagen2={imgFamilia2}
    />
  );
};

export default Familia;
