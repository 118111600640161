import React, { useEffect, useState } from "react";
import AreaDePractica from "../components/AreaDePractica.tsx";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Button from "../components/Button.tsx";
import imgAnaPerfil from "./../images/ana-perfil.png";
import imgFavoroPerfil from "./../images/favaro-perfil.png";
import imgWhatsapp from "./../svg/whatsapp.svg";
import imgFacebook from "./../svg/facebook-azul.svg";
import linkedinSVG from "./../svg/linkedin.svg";
import linkedinEnteroSVG from "./../svg/linkedin-entero.svg";
import ContactoForm from "../components/ContactoForm.tsx";
import ContactoYOficinas from "../components/ContactoYOficinas.tsx";
import SocialMedias from "../components/SocialMedias.tsx";
interface InicioProps {
  setOpenModal: Function;
  setSentOk: Function;
  nombreEnModal: string;
  setNombreEnModal: Function;
}

const Inicio: React.FC<InicioProps> = ({
  setOpenModal,
  setSentOk,
  nombreEnModal,
  setNombreEnModal,
}) => {
  useEffect(() => {
    //AOS.init();
    AOS.init({
      disable: "mobile",
    });
  }, []);

  return (
    <>
      <SocialMedias />
      <div
        className="main-image-container"
        data-aos="zoom-out"
        data-aos-duration="500"
      >
        <div className="main-image-left-cover">
          <div className="main-image-text">
            <div className="flex flex-col mb-2">
              <p className="text-bold text-4xl font-titles letter-spacing-0-1">
                Boniscontro
              </p>
              <p className="text-4xl -mt-0-75 mb-1 font-titles">Abogados</p>
              <p className="mb-2">Seriedad y especialización a su alcance.</p>
              <a
                //href="https://api.whatsapp.com/send/?phone=%2B5491157519971&text&app_absent=0"
                href="https://wa.me/5491157519971"
                target="_blank"
                rel="noopener"
                className="color-white flex w-full justify-center"
              >
                <button className="boton-ppal-red-social wapp flex items-center">
                  Consultanos por Whatsapp
                  <img
                    className="wapp-svg-en-btn"
                    src={imgWhatsapp}
                    alt="consultanos-por-wapp"
                  />
                </button>
              </a>
              <a
                href="https://www.facebook.com/Boniscontro-Abogados-101155588414003/?view_public_for=101155588414003&ref=page_internal"
                target="_blank"
                rel="noopener"
                className="color-white flex justify-center"
              >
                <button className="boton-ppal-red-social facebook flex items-center mt-1">
                  Seguinos en Facebook
                  <img
                    className="facebook-svg-en-btn"
                    src={imgFacebook}
                    alt="consultanos-por-facebook"
                  />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="w-full text-center pt-5 pb-2 bg-light-blue"
        id="areas-de-practica"
      >
        <p
          className="titulo-ppal"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-offset="0"
        >
          Áreas de Práctica
        </p>
        <div className="grid-container">
          <AreaDePractica
            titulo="FAMILIA"
            descripcion="Procuramos brindar contención y asesoramiento de calidad en cuestiones familiares."
            link="/familia"
          />
          <AreaDePractica
            titulo="SUCESIONES"
            descripcion="Nos especializamos en todo tipo de procesos sucesorios y planificación de herencias futuras."
            link="/sucesiones"
          />
          <AreaDePractica
            titulo="CONTRATOS"
            descripcion="Ofrecemos asesoramiento y la redacción o revisión de todo tipo de contratos."
            link="/contratos"
          />
          <AreaDePractica
            titulo="DEFENSA AL CONSUMIDOR"
            descripcion="Protegemos y respaldamos a nuestros clientes en situaciones en las que se vean afectados."
            link="/defensa-al-consumidor"
          />
          <AreaDePractica
            titulo="LABORAL"
            descripcion="Brindamos un asesoramiento integral tanto a trabajadores como a empleadores."
            link="/laboral"
          />
          <AreaDePractica
            titulo="SEGUROS"
            descripcion="Gestionamos los reclamos de siniestros ante todas las compañías aseguradoras."
            link="/seguros"
          />
          {/* <AreaDePractica
            titulo="ACCIDENTES DE TRÁNSITO"
            descripcion="Te asesoramos en el cobro de indemnizaciones por accidentes de tránsito."
            link="/accidentes-de-transito"
          />
          <AreaDePractica
            titulo="CONSORCIOS"
            descripcion="Ofrecemos a los consorcios un servicio de cobro de expensas adeudadas."
            link="/consorcios"
          /> */}
        </div>
      </div>
      <div className="container-elegirnos" id="porque-elegirnos-id">
        <div
          className="sm:w-50"
          data-aos="fade-right"
          data-aos-duration="2000"
          data-aos-offset="50"
        >
          <p className="titulo-ppal text-center">Por Qué Elegirnos</p>
        </div>
        <div
          className="sm:w-50"
          data-aos="fade-left"
          data-aos-duration="2000"
          data-aos-offset="50"
        >
          <ul className="ul-style mr-1">
            <li className="li-style-elegirnos">
              Asesoramiento Jurídico Personalizado.
            </li>
            <li className="li-style-elegirnos">
              Contención durante todo el proceso.
            </li>
            <li className="li-style-elegirnos">
              Seriedad, responsabilidad y experiencia.
            </li>
            <li className="li-style-elegirnos">Proximidad.</li>
            <li className="li-style-elegirnos">
              Métodos alternativos de resolución de conflictos.
            </li>
            <li className="li-style-elegirnos">
              Razonabilidad y flexibilidad en materia de honorarios.
            </li>
            <li className="li-style-elegirnos">
              Informes periódicos sobre el estado de las causas.
            </li>
            <li className="li-style-elegirnos">Enfoque interdisciplinario.</li>
          </ul>
          <Link to="/porque-elegirnos">
            <div className="w-full mt-2 sm:mt-4 flex justify-center">
              <Button
                text="Ver Más +"
                buttonClass="ver-mas-grande-fondo-azul sm:mr-4"
              />
            </div>
          </Link>
        </div>
      </div>
      <div className="w-full flex flex-col mb-4 pt-4" id="nuestro-equipo">
        <p
          className="titulo-ppal text-center mb-2"
          data-aos="zoom-in"
          data-aos-duration="1000"
          data-aos-offset="50"
        >
          Nuestro Equipo
        </p>
        <div className="w-full flex flex-col sm:flex-row justify-between items-center">
          <a
            href="https://www.linkedin.com/in/ana-maria-boniscontro-a47b0b113/"
            target="_blank"
            rel="noopener"
          >
            <div
              className="perfil-y-titulo sm:ml-12"
              data-aos="fade-down"
              data-aos-duration="1300"
              data-aos-offset="50"
            >
              <img
                className="imagen-perfil"
                src={imgAnaPerfil}
                alt="ana-perfil"
              />
              <p className="mt-1 text-center">Dra. Boniscontro</p>
              <Button
                text=""
                icon={
                  <img
                    className="svg-en-enviar linkedin mb-0-25 "
                    src={linkedinEnteroSVG}
                  />
                }
                buttonClass="ver-mas-grande-fondo-blanco mt-1 group-button w-fit-content mb-5 sm:mb-0 py-0-125 px-0"
              />
            </div>
          </a>
          <a
            href="https://www.linkedin.com/in/ana-sabrina-favaro-a038b51a/"
            target="_blank"
            rel="noopener"
          >
            <div
              className="perfil-y-titulo sm:mr-12"
              data-aos="fade-up"
              data-aos-duration="1300"
              data-aos-offset="50"
            >
              <img
                className="imagen-perfil"
                src={imgFavoroPerfil}
                alt="favoro-perfil"
              />
              <p className="mt-1 text-center">Dra. Favaro</p>
              <Button
                text=""
                icon={
                  <img
                    className="svg-en-enviar linkedin mb-0-25"
                    src={linkedinEnteroSVG}
                  />
                }
                buttonClass="ver-mas-grande-fondo-blanco mt-1 group-button w-fit-content py-0-125 px-0"
              />
            </div>
          </a>
        </div>
      </div>
      <div className="contactanos-image-container" id="contacto">
        <div className="w-full flex flex-col contactanos-image-container-cover pt-4">
          <p
            className="titulo-ppal text-center mb-0"
            data-aos="zoom-in"
            data-aos-duration="1000"
            data-aos-offset="100"
          >
            Contactenos
          </p>
          <p
            className="mb-2 -mt-0-5 text-center"
            data-aos="zoom-in"
            data-aos-duration="1000"
            data-aos-offset="100"
          >
            Estamos acá para asistirle.
          </p>
          <div
            className="w-full mb-1 flex flex-col sm:flex-row justify-between"
            data-aos="zoom-in"
            data-aos-duration="1000"
            data-aos-offset="100"
          >
            <ContactoForm
              setOpenModal={setOpenModal}
              setSentOk={setSentOk}
              nombreEnModal={nombreEnModal}
              setNombreEnModal={setNombreEnModal}
            />
            <ContactoYOficinas />
          </div>
        </div>
      </div>
    </>
  );
};

export default Inicio;
