import React, { useEffect, useState } from "react";
import AreaDePracticaRuta from "../components/AreaDePracticaRuta.tsx";
import imgConsorcios from "./../images/consorcios.jpg";

const Consorcios: React.FC = () => {
  return (
    <AreaDePracticaRuta
      titulo="Consorcios"
      primeraDescripcion={
        <div className="flex flex-col text-justify">
          <p className="mb-1">
            Nuestro Estudio ofrece a los consorcios de barrios privados y
            countrys o edificios un servicio de cobro de expensas adeudadas que
            tiene como finalidad el seguimiento de los morosos en todas sus
            situaciones, desde el momento en que entraron en mora, hasta su
            ejecución por la vía legal.
          </p>
          <p className="mb-1">
            Los honorarios de estudio jurídico son siempre, y sin excepción, a
            cargo de la unidad funcional morosa. De esta manera, el consorcio no
            desembolsa dinero alguno, más que los gastos que generen las
            diligencias como puede ser el envío de las cartas documento, el
            asiento registral solicitado al registro de la propiedad inmueble,
            etc.
          </p>
          <p className="mb-1">
            El estudio emite de manera mensual un informe del avance de las
            gestiones. De esta manera, tanto el administrador como los miembros
            del consejo, pueden tener acceso total y real de las unidades más
            comprometidas, como también de aquellas que fueron poniéndose al
            día.
          </p>
          <p className="-mb-2 sm:mb-1">Conozca nuestro plan de trabajo.</p>
        </div>
      }
      srcImagen1={imgConsorcios}
    />
  );
};

export default Consorcios;
