import React, { useEffect, useState } from "react";
import AreaDePracticaRuta from "../components/AreaDePracticaRuta.tsx";
import imgAccidentesDeTransito from "./../images/transito.jpg";

const AccidentesDeTransito: React.FC = () => {
  return (
    <AreaDePracticaRuta
      titulo="Accidentes De Tránsito"
      primeraDescripcion={
        <div className="flex flex-col text-justify">
          <p>
            En A&B nos especializamos en cobro de indemnizaciones por accidentes
            de tránsito, ante particulares y compañías de seguros.
          </p>
          <p className="mt-1 mb-0-25">
            Tiene derecho a ser indemnizado quien sufrió daños en su patrimonio
            o lesiones físicas como consecuencia de un accidente siendo:
          </p>
          <ul className="ul-style">
            <li className="li-style">Peatón.</li>
            <li className="li-style">
              Conductor de un automóvil o transportado.
            </li>
            <li className="li-style">Motociclista.</li>
            <li className="li-style">Ciclista.</li>
            <li className="li-style">
              Usuario de taxi, remis, uber o transporte público de pasajeros.
            </li>
          </ul>
          <p className="mt-1 mb-1">
            En cualquier de estos casos B&A puede ayudarlo en las gestiones
            judiciales o extrajudiciales, presentando el reclamo o iniciando la
            demanda por daños y perjuicios que pudiera corresponder.
          </p>
          <p className="mb-1">
            Nuestro estudio instrumentará los medios para que el damnificado o
            sus familiares reciban la indemnización que realmente les
            corresponden. En estos casos es importante no demorar la Consulta a
            un profesional.
          </p>
          <p className="mb-1">
            Las Empresas Aseguradoras que usted contrató para estar cubierto en
            estos casos no siempre defenderán sus intereses. Nuestro Estudio lo
            asesorará en los reclamos hacia su propia Aseguradora y también en
            el que pudiera corresponder contra la aseguradora de los otros
            involucrados.
          </p>
          <p className="mb-1">
            Recuerde que tiene 2 años para accionar. Si aún no reclamó su
            indemnización, HÁGALO.
          </p>
          <p className="-mb-2 sm:mb-1">
            NUESTROS HONORARIOS SE ABONAN CUANDO USTED COBRE. Nuestro negocio es
            su conformidad y futura recomendación. Solo ganamos, si usted gana.
            Si no logramos resultados, no cobramos. Por eso le garantizamos
            lealtad, responsabilidad y trabajo.
          </p>
        </div>
      }
      srcImagen1={imgAccidentesDeTransito}
    />
  );
};

export default AccidentesDeTransito;
