import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import personaSVG from "./../svg/persona.svg";
import emailSVG from "./../svg/email.svg";
import telefonoSVG from "./../svg/telefono.svg";
import asuntoSVG from "./../svg/asunto.svg";
import enviarSVG from "./../svg/enviar.svg";
import Button from "./Button.tsx";

interface ContactoFormProps {
  setOpenModal: Function;
  setSentOk: Function;
  nombreEnModal: string;
  setNombreEnModal: Function;
}

const ContactoForm: React.FC<ContactoFormProps> = ({
  setOpenModal,
  setSentOk,
  nombreEnModal,
  setNombreEnModal,
}) => {
  const form = useRef<any>();
  const [sending, setSending] = useState(false);

  const sendEmail = (e: any) => {
    setSending(true);
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5hs4l7p",
        "template_dz1urgj",
        form.current,
        "LshLQKoslnMR0sP10"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSentOk(true);
          setSending(false);
          //para borrar los valores del input
          e.target.reset();
          setOpenModal(true);
        },
        (error) => {
          console.log(error.text);
          setSentOk(false);
          setSending(false);
          setOpenModal(true);
        }
      );
  };

  return (
    <div
      className="contenedor-contacto"
      data-aos="zoom-in"
      data-aos-duration="1200"
      data-aos-offset="100"
    >
      <div className="flex flex-col text-center mb-1 mt-1">
        <h2 className="mb-0-5">Envíenos un Mensaje</h2>
        {/* <p>Envianos su consulta y nos contactaremos a la brevedad.</p> */}
        <p>
          No olvide completar todos los campos{" "}
          <span className="text-orange text-lg">*</span>
        </p>
        <p className="mt-0-5">Muchas Gracias!</p>
      </div>
      <form
        ref={form}
        onSubmit={sendEmail}
        className="px-1 flex flex-col w-full"
      >
        <div className="flex flex-col sm:flex-row itemes-center w-full">
          <div className="flex relative sm:w-50 mb-1 sm:mb-0 mr-1 sm:mr-0 ml-1">
            <input
              type="text"
              name="de_nombre"
              placeholder="Su Nombre"
              required={true}
              className="input-en-contacto"
              value={nombreEnModal}
              onChange={(e) => setNombreEnModal(e.target.value)}
            />
            <img className="svg-en-contacto" src={personaSVG} />
          </div>

          <div className="flex relative sm:w-50 mx-1">
            <input
              type="email"
              name="de_email"
              placeholder="Su Email"
              required={true}
              className="input-en-contacto"
            />
            <img className="svg-en-contacto" src={emailSVG} />
          </div>
        </div>
        <div className="flex flex-col sm:flex-row itemes-center w-full my-1">
          <div className="flex relative sm:w-50 mb-1 sm:mb-0 mr-1 sm:mr-0 ml-1">
            <input
              type="text"
              name="de_telefono"
              placeholder="Su Teléfono"
              required={true}
              className="input-en-contacto"
            />
            <img className="svg-en-contacto" src={telefonoSVG} />
          </div>

          <div className="flex relative sm:w-50 mx-1">
            <input
              type="text"
              name="asunto"
              placeholder="Asunto"
              required={false}
              className="input-en-contacto"
            />
            <img className="svg-en-contacto" src={asuntoSVG} />
          </div>
        </div>

        <div className="mx-1 mb-1">
          <textarea
            className="text-area-en-contacto"
            name="mensaje"
            placeholder="Mensaje"
            rows={5}
            required={true}
          />
        </div>
        <div className="w-full flex justify-center mb-1">
          <Button
            text={sending ? "Enviando" : "Enviar"}
            type="submit"
            icon={
              sending ? null : (
                <img
                  className="svg-en-enviar ml-0-25 -mr-0-4"
                  src={enviarSVG}
                />
              )
            }
            loading={sending}
            buttonClass={`ver-mas-grande-fondo-blanco group-button ${
              sending ? "disabled w-10" : ""
            }`}
          />
        </div>
      </form>
    </div>
  );
};

export default ContactoForm;
