import React from "react";
import googleMapsSVG from "./../svg/google-maps.svg";

const ContactoYOficinas: React.FC = () => {
  return (
    <>
      <div className="contenedor-contacto-derecha">
        <div
          className="contenedor-contacto w-full flex-grow-1"
          data-aos="zoom-in"
          data-aos-duration="2000"
          data-aos-offset="100"
        >
          <div className="flex flex-col text-center mb-2 mt-1">
            <h2 className="mb-0-5">Contacto</h2>
            <p>DRA. BONISCONTRO</p>
            <p className="my-0-5">(+ 54 911) 5751-9971</p>
            <p>dra.boniscontro@gmail.com</p>
          </div>
        </div>
        <div
          className="contenedor-contacto w-full mt-2 mb-2 sm:mb-0 flex-grow-1"
          data-aos="zoom-in"
          data-aos-duration="2400"
          data-aos-offset="0"
        >
          <div className="flex flex-col text-center mb-2 mt-1">
            <h2 className="mb-0-5">Oficinas</h2>
            <p>DIRECCIÓN</p>
            <p>Las Amapolas 325, Manuel Alberti, Provincia de Buenos Aires.</p>
            <p>Edificio SkyGlass II, 2do piso, oficina 40.</p>
            <div className="group-oficinas flex justify-center items-center underline mt-1 cursor-pointer">
              <img className="google-maps-svg" src={googleMapsSVG} />
              <a
                href="https://maps.app.goo.gl/s96ovpxGohLH5kwU6"
                target="_blank"
                rel="noopener"
                className="abrir-google"
              >
                Abrir en Google Maps
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactoYOficinas;
