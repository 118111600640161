import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Inicio from "./pages/Inicio.tsx";
import Laboral from "./pages/Laboral.tsx";
import Familia from "./pages/Familia.tsx";
import Sucesiones from "./pages/Sucesiones.tsx";
import DefensaAlConsumidor from "./pages/DefensaAlConsumidor.tsx";
import AccidentesDeTransito from "./pages/AccidentesDeTransito.tsx";
import Seguros from "./pages/Seguros.tsx";
import Consorcios from "./pages/Consorcios.tsx";
import Contratos from "./pages/Contratos.tsx";
import Topbar from "./components/Topbar.tsx";
import PorqueElegirnos from "./pages/PorqueElegirnos.tsx";
import Footer from "./components/Footer.tsx";
import ModalEmail from "./components/ModalEmail.tsx";
import ScrollUp from "./components/ScrollUp.tsx";

const ScrollToTopOnMount = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App: React.FC = () => {
  //Estos 3 estados son para el modal de confirmacion solamente (lo correcto seria hacer un Context)
  const [nombreEnModal, setNombreEnModal] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [sentOk, setSentOk] = useState(false);
  //
  return (
    <>
      <div>
        <BrowserRouter>
          <ScrollToTopOnMount />
          <Topbar />
          <Routes>
            <Route
              path="/"
              element={
                <Inicio
                  setOpenModal={setOpenModal}
                  setSentOk={setSentOk}
                  nombreEnModal={nombreEnModal}
                  setNombreEnModal={setNombreEnModal}
                />
              }
            />
            <Route path="/laboral" element={<Laboral />} />
            <Route path="/familia" element={<Familia />} />
            <Route path="/sucesiones" element={<Sucesiones />} />
            <Route path="/contratos" element={<Contratos />} />
            <Route
              path="/defensa-al-consumidor"
              element={<DefensaAlConsumidor />}
            />
            <Route
              path="/accidentes-de-transito"
              element={<AccidentesDeTransito />}
            />
            <Route path="/seguros" element={<Seguros />} />
            <Route path="/consorcios" element={<Consorcios />} />
            <Route path="/porque-elegirnos" element={<PorqueElegirnos />} />
          </Routes>
          <ScrollUp />
          <Footer />
          {openModal && (
            <ModalEmail
              sentOk={sentOk}
              onClose={() => setOpenModal(false)}
              nombre={nombreEnModal}
            />
          )}
        </BrowserRouter>
      </div>
    </>
  );
};

export default App;
