import React from "react";

interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  text: string | any;
  buttonClass: string;
  icon?: React.ReactElement | null;
  type?: "button" | "submit";
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  text,
  buttonClass,
  icon,
  type = "button",
  loading,
}) => {
  return (
    <button type={type} onClick={onClick} className={buttonClass}>
      <div className={`flex items-center `}>
        <span>{text}</span>
        {icon && icon}
        {loading && <div className="ml-0-25 loading"></div>}
      </div>
    </button>
  );
};

export default Button;
